import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Strong, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Inkspire Tattoo Studio
			</title>
			<meta name={"description"} content={"Ваше полотно, наше мистецтво"} />
			<meta property={"og:title"} content={"Inkspire Tattoo Studio"} />
			<meta property={"og:description"} content={"Ваше полотно, наше мистецтво"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6672ada08d4a0c002030a5b6/images/2-3%20%281%29.jpg?v=2024-06-19T11:23:01.175Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6672ada08d4a0c002030a5b6/images/2-3%20%281%29.jpg?v=2024-06-19T11:23:01.175Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6672ada08d4a0c002030a5b6/images/2-3%20%281%29.jpg?v=2024-06-19T11:23:01.175Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6672ada08d4a0c002030a5b6/images/2-3%20%281%29.jpg?v=2024-06-19T11:23:01.175Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6672ada08d4a0c002030a5b6/images/2-3%20%281%29.jpg?v=2024-06-19T11:23:01.175Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6672ada08d4a0c002030a5b6/images/2-3%20%281%29.jpg?v=2024-06-19T11:23:01.175Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6672ada08d4a0c002030a5b6/images/2-3%20%281%29.jpg?v=2024-06-19T11:23:01.175Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section min-height="100vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Що ми пропонуємо
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					У Inkspire Tattoo Studio ми пропонуємо різноманітні послуги з татуювань, розроблені відповідно до унікального стилю та бачення кожного клієнта. Наші кваліфіковані майстри створюють високоякісні індивідуальні татуювання, які ви будете пишатися все життя. Незалежно від того, чи любите ви татуювання, чи новачок, наша студія пропонує гостинне та професійне середовище, де ваші ідеї можуть процвітати.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://uploads.quarkly.io/6672ada08d4a0c002030a5b6/images/1-3.jpg?v=2024-06-19T11:23:01.154Z) bottom/cover no-repeat scroll padding-box"
				lg-min-height="400px"
				lg-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 100% 75%/cover no-repeat scroll padding-box"
				md-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 100% 100%/cover no-repeat scroll padding-box"
				sm-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 50% 100%/cover no-repeat scroll padding-box"
			/>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://uploads.quarkly.io/6672ada08d4a0c002030a5b6/images/2-2.jpg?v=2024-06-19T11:23:01.151Z"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6672ada08d4a0c002030a5b6/images/2-2.jpg?v=2024-06-19T11%3A23%3A01.151Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6672ada08d4a0c002030a5b6/images/2-2.jpg?v=2024-06-19T11%3A23%3A01.151Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6672ada08d4a0c002030a5b6/images/2-2.jpg?v=2024-06-19T11%3A23%3A01.151Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6672ada08d4a0c002030a5b6/images/2-2.jpg?v=2024-06-19T11%3A23%3A01.151Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6672ada08d4a0c002030a5b6/images/2-2.jpg?v=2024-06-19T11%3A23%3A01.151Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6672ada08d4a0c002030a5b6/images/2-2.jpg?v=2024-06-19T11%3A23%3A01.151Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6672ada08d4a0c002030a5b6/images/2-2.jpg?v=2024-06-19T11%3A23%3A01.151Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="center"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Кожне татуювання в Inkspire починається з консультації, щоб зрозуміти ваші ідеї та натхнення. Наші художники працюють з вами, щоб створити індивідуальний дизайн, який відображатиме вашу особистість та естетичні уподобання. Ми гарантуємо, що ваше татуювання стане справжнім витвором мистецтва, створеним спеціально для вас, від детальних ескізів до остаточного нанесення.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			md-padding="80px 0 80px 0"
			background="--color-darkL1"
			color="--dark"
			quarkly-title="Reviews-14"
		>
			<Override slot="SectionContent" md-padding="0px 0 0px 0" grid-template-columns="repeat(3, 1fr)" grid-template-rows="auto" />
			<Text margin="0px 0px 15px 0px" color="--light" font="normal 600 46px/1.2 --fontFamily-sans" text-align="center">
				Асортимент пропозицій
			</Text>
			<Text margin="0px 0px 50px 0px" color="#b2bcc3" font="normal 300 22px/1.5 --fontFamily-sansHelvetica" text-align="center">
				Наш доброзичливий та досвідчений персонал допоможе вам на кожному етапі процесу та відповість на будь-які ваші запитання.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Традиційні татуювання{"\n"}
								<br />
							</Strong>
							<br />
							Сміливі та яскраві: наші традиційні татуювання мають сміливі лінії та яскраві кольори, пропонуючи незмінний дизайн, який виділяється.
Класичні символи: від якорів до троянд, наші традиційні татуювання містять знакові символи, які витримали випробування часом.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Реалістичні татуювання{"\n"}
								<br />
							</Strong>
							<br />
							Фотореалістичні портрети: Передайте сутність коханої людини чи кумира за допомогою наших приголомшливо реалістичних портретних татуювань.
Природа та дика природа: перенесіть красу природи на свою шкіру за допомогою наших детальних татуювання тварин і пейзажів.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Акварельні татуювання
								<br />
							</Strong>
							<br />
							{"\n"}Рідкі та мрійливі: наші акварельні татуювання плавно поєднують кольори, створюючи прекрасний живописний ефект.
Абстрактні малюнки: Проявіть свою творчість за допомогою наших унікальних абстрактних акварельних татуювань.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Геометричні татуювання{"\n"}
								<br />
							</Strong>
							<br />
							Точність і симетрія: наші геометричні татуювання мають точні лінії та симетричні візерунки для сучасного, стильного вигляду.
Сакральна геометрія: досліджуйте духовні та містичні теми за допомогою наших складних татуювань із сакральною геометрією.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Татуювання Cover-Up{"\n"}
								<br />
							</Strong>
							<br />
							Перетворіть старі татуювання: Вдихніть нове життя в старі або непотрібні татуювання за допомогою наших майстерно розроблених масок.
Індивідуальні рішення: Наші майстри працюють з вами, щоб створити покриття, яке не тільки приховає, але й підкреслить ваше оригінальне татуювання.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Em>
								Цей огляд — лише короткий огляд широкого спектру послуг, які ми пропонуємо в Inkspire Tattoo Studio. Для більш детального обговорення ваших ідей татуювань і ознайомлення з усіма нашими пропозиціями ми запрошуємо вас зв’язатися з нами або відвідати нашу студію. Наш доброзичливий та досвідчений персонал допоможе вам на кожному етапі процесу та відповість на будь-які ваші запитання.
							</Em>
						</Text>
					</Box>
				</Box>
			</Box>
			<Text margin="0px 0px 50px 0px" color="#b2bcc3" font="normal 300 22px/1.5 --fontFamily-sansHelvetica" text-align="center">
				У Inkspire Tattoo Studio ми прагнемо зробити ваше татуювання таким же винятковим і надихаючим, як саме мистецтво. Ми з нетерпінням чекаємо на співпрацю з вами та створення шедевру, який ви будете цінувати назавжди.
			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://uploads.quarkly.io/6672ada08d4a0c002030a5b6/images/1-4.jpg?v=2024-06-19T11:23:01.138Z"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6672ada08d4a0c002030a5b6/images/1-4.jpg?v=2024-06-19T11%3A23%3A01.138Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6672ada08d4a0c002030a5b6/images/1-4.jpg?v=2024-06-19T11%3A23%3A01.138Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6672ada08d4a0c002030a5b6/images/1-4.jpg?v=2024-06-19T11%3A23%3A01.138Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6672ada08d4a0c002030a5b6/images/1-4.jpg?v=2024-06-19T11%3A23%3A01.138Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6672ada08d4a0c002030a5b6/images/1-4.jpg?v=2024-06-19T11%3A23%3A01.138Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6672ada08d4a0c002030a5b6/images/1-4.jpg?v=2024-06-19T11%3A23%3A01.138Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6672ada08d4a0c002030a5b6/images/1-4.jpg?v=2024-06-19T11%3A23%3A01.138Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="center"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Наші художники є серцем Inkspire Tattoo Studio. Кожен член нашої команди має багатий досвід і особливий художній стиль, що гарантує, що ми можемо запропонувати широкий вибір жанрів татуювань. Від традиційних і неотрадиційних до реалізму, акварелі та геометричних малюнків, наші художники мають навички та креативність, щоб втілити ваше бачення в життя. Вони не лише майстри своєї справи, але й віддані постійному навчанню та вдосконаленню, залишаючись у курсі останніх тенденцій і технік у індустрії татуювань.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});